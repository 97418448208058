@import url(https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", "AIA Everest",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@font-face {
  font-family: 'digital-7';
  src: url(/static/media/digital-7.1e670d88.ttf);
  /* IE9 Compat Modes */
}

html {
  overflow: -moz-scrollbars-vertical;
  overflow-y: scroll;
}

@font-face {
  font-family: 'AIA Everest Condensed';
  src: url(/static/media/AIAEverest-Condensed.30192308.woff2) format('woff2'),
      url(/static/media/AIAEverest-Condensed.fb1a4021.woff) format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'AIA Everest';
  src: url(/static/media/AIAEverest-Bold.e8cb0c3a.woff2) format('woff2'),
      url(/static/media/AIAEverest-Bold.a1516ac4.woff) format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'AIAEverestBold';
  src: url(/static/media/AIAEverestBold.455370ce.woff2) format('woff2'),
      url(/static/media/AIAEverestBold.11614aee.woff) format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'AIAEverestCondensedMedium';
  src: url(/static/media/AIAEverestCondensedMedium.95afcb86.woff2) format('woff2'),
      url(/static/media/AIAEverestCondensedMedium.b946feef.woff) format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'AIA Everest';
  src: url(/static/media/AIAEverest-Regular.0e050229.woff2) format('woff2'),
      url(/static/media/AIAEverest-Regular.cb03b3f4.woff) format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'AIA Everest Condensed';
  src: url(/static/media/AIAEverest-CondensedMedium.f5839227.woff2) format('woff2'),
      url(/static/media/AIAEverest-CondensedMedium.961aa95d.woff) format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'AIAEverestExtraBold';
  src: url(/static/media/AIAEverestExtraBold.44f7bed2.woff2) format('woff2'),
      url(/static/media/AIAEverestExtraBold.0083bf2c.woff) format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'AIAEverestCondensed';
  src: url(/static/media/AIAEverestCondensed.a82507c0.woff2) format('woff2'),
      url(/static/media/AIAEverestCondensed.a8b4d95e.woff) format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'AIAEverestRegular';
  src: url(/static/media/AIAEverestRegular.acf6322d.woff2) format('woff2'),
      url(/static/media/AIAEverestRegular.861c54f9.woff) format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'AIA Everest';
  src: url(/static/media/AIAEverest-Medium.c8b9e1a9.woff2) format('woff2'),
      url(/static/media/AIAEverest-Medium.7c2aea4a.woff) format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'AIA Everest';
  src: url(/static/media/AIAEverest-ExtraBold.a092ff41.woff2) format('woff2'),
      url(/static/media/AIAEverest-ExtraBold.b7097d1f.woff) format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'AIAEverestMedium';
  src: url(/static/media/AIAEverestMedium.8713f8bb.woff2) format('woff2'),
      url(/static/media/AIAEverestMedium.65e041f0.woff) format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}
