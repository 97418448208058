body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", "AIA Everest",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@font-face {
  font-family: 'digital-7';
  src: url('./fonts/digital-7.ttf');
  /* IE9 Compat Modes */
}

html {
  overflow: -moz-scrollbars-vertical;
  overflow-y: scroll;
}

@font-face {
  font-family: 'AIA Everest Condensed';
  src: url('./fonts/AIAEverest-Condensed.woff2') format('woff2'),
      url('./fonts/AIAEverest-Condensed.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'AIA Everest';
  src: url('./fonts/AIAEverest-Bold.woff2') format('woff2'),
      url('./fonts/AIAEverest-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'AIAEverestBold';
  src: url('./fonts/AIAEverestBold.woff2') format('woff2'),
      url('./fonts/AIAEverestBold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'AIAEverestCondensedMedium';
  src: url('./fonts/AIAEverestCondensedMedium.woff2') format('woff2'),
      url('./fonts/AIAEverestCondensedMedium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'AIA Everest';
  src: url('./fonts/AIAEverest-Regular.woff2') format('woff2'),
      url('./fonts/AIAEverest-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'AIA Everest Condensed';
  src: url('./fonts/AIAEverest-CondensedMedium.woff2') format('woff2'),
      url('./fonts/AIAEverest-CondensedMedium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'AIAEverestExtraBold';
  src: url('./fonts/AIAEverestExtraBold.woff2') format('woff2'),
      url('./fonts/AIAEverestExtraBold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'AIAEverestCondensed';
  src: url('./fonts/AIAEverestCondensed.woff2') format('woff2'),
      url('./fonts/AIAEverestCondensed.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');

@font-face {
  font-family: 'AIAEverestRegular';
  src: url('./fonts/AIAEverestRegular.woff2') format('woff2'),
      url('./fonts/AIAEverestRegular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'AIA Everest';
  src: url('./fonts/AIAEverest-Medium.woff2') format('woff2'),
      url('./fonts/AIAEverest-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'AIA Everest';
  src: url('./fonts/AIAEverest-ExtraBold.woff2') format('woff2'),
      url('./fonts/AIAEverest-ExtraBold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'AIAEverestMedium';
  src: url('./fonts/AIAEverestMedium.woff2') format('woff2'),
      url('./fonts/AIAEverestMedium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}